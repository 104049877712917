import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Прокат автомобілів Новий Шлях
			</title>
			<meta name={"description"} content={"У компанії Новий Шлях ми віримо, що подорож повинна бути такою ж винятковою, як і місце призначення. "} />
			<meta property={"og:title"} content={"Наші послуги | Прокат автомобілів Новий Шлях"} />
			<meta property={"og:description"} content={"У компанії Новий Шлях ми віримо, що подорож повинна бути такою ж винятковою, як і місце призначення. "} />
			<meta property={"og:image"} content={"https://visiraindes.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://visiraindes.com/img/3061669.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://visiraindes.com/img/3061669.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://visiraindes.com/img/3061669.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://visiraindes.com/img/3061669.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://visiraindes.com/img/3061669.png"} />
			<meta name={"msapplication-TileImage"} content={"https://visiraindes.com/img/3061669.png"} />
			<meta name={"msapplication-TileColor"} content={"https://visiraindes.com/img/3061669.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-primary"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Що може запропонувати вам прокат автомобілів Новий Шлях
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					У компанії Новий Шлях ми віримо, що подорож повинна бути такою ж винятковою, як і місце призначення. Наш пакет послуг розроблений для того, щоб зробити ваш досвід оренди безперешкодним, комфортним та адаптованим до розповідей про ваші пригоди.
				</Text>
				<Link
					href="/contact-us"
					color="--dark"
					text-decoration-line="initial"
					padding="7px 24px 8px 24px"
					background="--color-secondary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="1px"
					border-radius="8px"
				>
					Написати
				</Link>
			</Box>
			<Box
				display="flex"
				width="60%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://visiraindes.com/img/4.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					max-height="600px"
					width="100%"
					object-position="0% 70%"
					sm-max-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 120px 0" sm-padding="60px 0 60px 0" quarkly-title="Cards-8">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="flex-start"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 70px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					text-align="left"
					sm-font="normal 700 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					width="50%"
					lg-width="100%"
				>
					Послуги
				</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box>
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Індивідуальний транспорт
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								- Різноманітний автопарк - від затишних компакт-класів до просторих позашляховиків, наш автопарк підібраний відповідно до вашої подорожі.
								<br />
								<br />
								- Автомобілі, готові до пригод - Кожен автомобіль оснащений найнеобхіднішим для мандрівки: від GPS для навігації до місткого багажника для зберігання ваших речей.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Підтримка в дорозі
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								- 24/7 допомога на дорозі - Будьте спокійні, знаючи, що підтримка доступна в будь-який час, гарантуючи, що ваша історія продовжиться безперешкодно.
								<br />
								<br />
								- Гнучкий час оренди - Час - це лише число. Орендуйте погодинно, на день або на тиждень на простих і зрозумілих умовах.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Покращення подорожі
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								- Додаткові зручності - дитячі крісла для маленьких дослідників, додаткові опції водія для спільних розділів та багато іншого, щоб зробити вашу подорож більш індивідуальною.
								<br />
								<br />
								- Заздалегідь сплановані маршрути - запитуйте індивідуальні пропозиції щодо маршрутів, які приведуть вас до прихованих скарбів і мальовничих стежок.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
					>
						<Box>
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Спрощені процеси
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								- Швидка реєстрація та виїзд - витрачайте менше часу на очікування та більше часу на дослідження завдяки нашому спрощеному процесу оренди.
								<br />
								<br />
								- Прозоре ціноутворення - чесна ціна означає відсутність сюжетних поворотів або несподіваних розділів у вашій історії прокату.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Сервіс відповідає дорозі вашої історії.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Щоб розпочати наступну главу вашої подорожі з компанією Новий Шлях  або дізнатися про деталі наших послуг, будь ласка, зв'яжіться з нами безпосередньо. Давайте зробимо вашу майбутню подорож історією, яку ви запам'ятаєте.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://visiraindes.com/img/5.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="1 / span 1"
					border-radius="24px"
				/>
				<Image
					src="https://visiraindes.com/img/6.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});